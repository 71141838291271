<template>
    <v-container fluid>
        <v-layout wrap>
            <v-flex fluid>
                <v-card outlined>
                    <v-card-title class="px-3">
                        <p class="font-weight-black">
                            Coleta Assinatura
                        </p>
                    </v-card-title>
                    <v-card class="px-1">
                        <v-row cols="12" sm="6">
                            <!-- Escolha do Paciente -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete outlined dense chips small-chips label="Paciente"></v-autocomplete>
                            </v-col>
                            <!-- Data de nascimnto -->
                            <v-col cols="12" sm="6">
                                <v-text-field outlined readonly dense label="Data de Nascimento"></v-text-field>
                            </v-col>
                            <!-- Procedimento para associar a assinatura -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete outlined readonly dense label="Escolher Procedimento"></v-autocomplete>
                            </v-col>
                            <!-- Evolução para associar a assinatura -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete outlined readonly dense label="Escolher Evoluçao"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-col>
                            <v-textarea label="Assine Aqui" auto-grow outlined rows="4" row-height="70"></v-textarea>
                        </v-col>
                        <v-col>
                            <v-row justify="space-around">
                                <v-btn color="green" rounded dark>
                                    Salvar
                                </v-btn>
                                <v-btn color="blue" rounded dark>
                                    Limpar
                                </v-btn>
                                <v-btn color="error" rounded>
                                    Cancelar
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "coleta_assinatura",
}
</script>